import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Modal, Slide } from '@mui/material';
import * as uuid from "uuid";

import './SongSelector.css'
import { useAppDispatch, useAppSelector } from '../../../model/Hooks';
import { selectFile, setFile } from '../../../model/reducers/RemaykGenReducer';

interface CatalogSong {
  id: string
  title: string
  author: string
  url: string
  coverUrl: string
}

const SONGS: CatalogSong[] = [
  {
    "id": "KB8DBo",
    "title": "Drunk Text Me",
    "author": "Lexi Jayde",
    "url": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Lexi%20Jayde%20-%20drunk%20text%20me.mp3",
    "coverUrl": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Drunk%20Text%20Me.png"
  },
  {
    "id": "mhUvQ9",
    "title": "The Star Spangled Banner",
    "author": "Andrew Young",
    "url": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Andrew-Young-The-Star-Spangled-Banner.mp3",
    "coverUrl": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/The%20Star%20Spangled%20Banner.png"
  },
  {
    "id": "p4Ktf5",
    "title": "Happy Birthday Song",
    "author": "Mark Humble",
    "url": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Mark%20Humble%20-%20Happy%20Birthday.mp3",
    "coverUrl": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Happy%20Birthday.png"
  },
  {
    "id": "92NFD8",
    "title": "Ave Maria",
    "author": "Karstenholymoly",
    "url": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Karstenholymoly%20-%20Ave%20Maria.mp3",
    "coverUrl": "https://storage.googleapis.com/covers-ai.appspot.com/song_catalog/Ave%20Maria.png"
  }
]

export const SongSelector = () => {
    const [showOptionList, setShowOptionList] = useState(false)
    const [playingSongId, setPlayingSongId] = useState<string>()
    const dispatch = useAppDispatch()

    useEffect(() => {
      SONGS.forEach((song) => {
        const audio = document.getElementById(song.id) as HTMLAudioElement | null
        if (!audio) { return }

        playingSongId === song.id ? audio.play() : audio.pause()
    })
    }, [playingSongId])

    useEffect(() => {
      if (!showOptionList) {
        setPlayingSongId(undefined)
      }
    }, [showOptionList])
  
    const fileInputRef = useRef<HTMLInputElement>(null);
    const file = useAppSelector(selectFile)
    let buttonText = file?.name ? file.name : 'Choose or upload a song'

    const handleFileSelection = async (event: ChangeEvent<HTMLInputElement>) => {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files == null) {
        return;
      }
      const file = fileInput.files[0]
      const url = URL.createObjectURL(file)
      setShowOptionList(false)
      dispatch(setFile({ url: url, name: file.name }))
    }

    const handleCatalogSongClick = (song: CatalogSong) => {
      setShowOptionList(false)
      dispatch(setFile({ url: song.url, name: song.title }))
    }

    const handlePlayback = (song: CatalogSong) => {
      setPlayingSongId(playingSongId ? undefined : song.id)
    }
  
    return (
        <div className='SongSelector-container'>
          <div className='SongSelector-button' onClick={() => {
            setShowOptionList(true)
          }}>
            {file &&
              <img className='SongSelector-image' src='/wave.png' />
            }
            <div className='SongSelector-title'>
              {buttonText}
            </div>
            <img className='SongSelector-arrow' src='/arrow_down.png' />
          </div>
            <form>
            <input
                type="file"
                name="audio"
                id="audio"
                accept="*"
                required
                ref={fileInputRef}
                onChange={(event) => handleFileSelection(event)}
                style={{display: "none"}}
            />
            </form>
            <Modal
            open={showOptionList}
            onClose={() => {
              setShowOptionList(false)
            }}
            sx={{
              display: "flex",
              flexDirection: 'column',
              justifyContent: "flex-end",
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            }}>
              <Slide direction='up' in={showOptionList} mountOnEnter unmountOnExit>
                <div className='SongSelector-options-container'>
                  <div className="SongSelector-options">
                    <div className="SongSelector-upload" onClick={() => {
                      fileInputRef.current?.click()
                    }}>
                    <img src='/plus_voice.png' className="SongSelector-upload-image" />
                        <div>
                            <div>Upload your file</div>
                            <div className="SongSelector-upload-hint">(mp3, wav, m4a, mp4)</div>
                        </div>
                    </div>
                    <UrlInput onLinkChange={(link) => {
                      dispatch(setFile({ name: link, url: link }))
                      setShowOptionList(false)
                    }}/>
                    {SONGS.map(song => {
                      return (
                      <div
                      className="SongSelector-catalog-song"
                      key={song.id}>
                          <div className='SongSelector-catalog-images' onClick={() => handlePlayback(song)}>
                            <img src={song.coverUrl} className="SongSelector-catalog-image" />
                            {playingSongId !== song.id && 
                              <img src='/play.png' className='SongSelector-catalog-play' />
                            }
                          </div>
                          <div className='SongSelector-catalog-labels' onClick={() => handleCatalogSongClick(song)}>
                              <div>{song.title}</div>
                              <div className="SongSelector-catalog-author">{`by ${song.author}`}</div>
                          </div>
                          <audio loop id={song.id}>
                            <source src={song.url} type='audio/mp3'/>
                          </audio>
                      </div>
                      )
                    })}
                  </div>
                </div>
              </Slide>
            </Modal>
        </div>
    )
}

interface UrlInputProps {
  onLinkChange: (link: string) => void
}

const UrlInput = (props: UrlInputProps) => {
  const [link, setLink] = useState<string>()

  return (
    <div className='SongSelector-url-container'>
      <img className='SongSelector-url-image' src='/link.png' />
      <input
      className='SongSelector-url-input'
      placeholder='Enter your audio url'
      type = "url"
      onChange={evt => {
        if (isValidUrl(evt.target.value)) {
          setLink(evt.target.value)
        } else {
          setLink(undefined)
        }
       }} />
      <div className={`SongSelector-url-done${link ? '' : '-disabled'}`} onClick={() => {
        if (link) {
          props.onLinkChange(link)
        }
      }}>
        Done
      </div>
    </div>
  )
}

const isValidUrl = (urlString: string) => {
  try { 
      return Boolean(new URL(urlString)); 
  }
  catch(e){ 
      return false; 
  }
}

export default SongSelector