import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../Store'

export const prompts: Prompt[] = [
  {
      "id": "classical-indian",
      "title": "Classical indian",
      "prompt": "Classical indian music; Craft a spiritual and meditative classical Indian piece using sitar or sarod melodies, tabla rhythms, tanpura drone, and intricate raga improvisations"
  },
  { 
      "id": "lofi",
      "title": "Lofi",
      "prompt": "Lofi beat; Generate a chill, downtempo instrumental with soft drums, vinyl crackles, and gentle piano chords reminiscent of a rainy day"
  },
  { 
      "id": "hyperpop",
      "title": "Hyperpop",
      "prompt": "Hyperpop beat; Create a vibrant and glitchy track, incorporating energetic beats, futuristic synths, and auto-tuned vocal chops"
  },
  { 
      "id": "hip-Hop",
      "title": "Hip-Hop",
      "prompt": "Hip-Hop beat; Craft a beat with deep bass lines, snappy snares, and rhythmic hi-hats, inspired by urban cityscapes and street culture"
  },
  { 
      "id": "rap",
      "title": "Rap",
      "prompt": "Rap beat; Craft a gritty urban rap beat with deep bass lines, snappy snares, and echoing street ambiance"
  },
  { 
      "id": "k-pop",
      "title": "K-Pop",
      "prompt": "K-Pop beat; Design a catchy instrumental incorporating electronic beats, bright synthesizers, and a touch of traditional Korean instruments"
  },
  { 
      "id": "pop",
      "title": "Pop",
      "prompt": "Pop beat; compose a radio-ready track with catchy melodies, pulsating drums, and shimmering synths, perfect for top 40 charts"
  },
  { 
      "id": "country",
      "title": "Country",
      "prompt": "Country beat; Generate a tune featuring acoustic guitars, harmonicas, and gentle percussion, reminiscent of a southern countryside"
  },
  { 
      "id": "acoustic",
      "title": "Acoustic",
      "prompt": "Acoustic Guitar; produce a soothing instrumental focusing on fingerstyle guitar, with subtle undertones of nature sounds"
  },
  { 
      "id": "trance",
      "title": "Trance",
      "prompt": "Trance beat; Craft a pulsating and ethereal track with fast-paced beats, atmospheric pads, and uplifting synth melodies"
  },
  { 
      "id": "metal",
      "title": "Metal",
      "prompt": "Metal beat; design a heavy instrumental with powerful guitar riffs, rapid drumming, and intense bass lines"
  },
  { 
      "id": "classical",
      "title": "Classical",
      "prompt": "Classical orchestra; Compose a piece using a full orchestra, focusing on violins, cellos, and woodwinds, taking inspiration from 19th-century symphonies"
  },
  { 
      "id": "edm",
      "title": "EDM",
      "prompt": "EDM beat; Produce an energetic track with dynamic drops, captivating build-ups, and synthesized melodies perfect for a festival crowd"
  },
  { 
      "id": "jazz",
      "title": "Jazz",
      "prompt": "Jazz beat; Generate a smooth instrumental with improvisational saxophone melodies, walking bass lines, and swing drum rhythms"
  },
  { 
      "id": "latin",
      "title": "Latin",
      "prompt": "Latin Reggaeton beat; Craft a sultry Latin reggaeton track with infectious dembow rhythms, tropical synths, and pulsating basslines"
  },
  { 
      "id": "afrobeats",
      "title": "Afrobeats",
      "prompt": "Afrobeats beat; Design an upbeat instrumental blending traditional African rhythms with modern percussion, and infectious melodies"
  },
  { 
      "id": "r&b",
      "title": "R&B",
      "prompt": "R&B beat; Compose a soulful tune with smooth bass lines, sultry keyboard chords, and rhythmic beats perfect for a romantic evening"
  },
  { 
      "id": "uk-garage",
      "title": "UK Garage",
      "prompt": "UK Garage beat; Produce a bouncy UK Garage track with syncopated rhythms, deep basslines, and chopped vocal samples"
  },
  { 
      "id": "dubstep",
      "title": "Dubstep",
      "prompt": "Dubstep; Craft a heavy dubstep track with deep wobbly basslines, chopped and screwed vocal samples, syncopated beats, and intense electronic drops"
  },
  { 
      "id": "trap",
      "title": "Trap",
      "prompt": "Trap; Produce a dark, atmospheric trap track with booming 808s, haunting melodies, and rapid hi-hats"
  }
]

export interface Prompt {
  id: string
  title: string
  prompt: string
}

export interface FileUpload {
  url: string
  name: string
}

interface RemaykGenState {
  prompt?: Prompt
  prompts: Prompt[]
  file?: FileUpload
}

const initialState: RemaykGenState = {
  prompt: undefined,
  file: undefined,
  prompts: prompts
}

export const RemaykGenSlice = createSlice({
  name: 'remaykGen',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<FileUpload>) => {
      state.file = action.payload
    },
    setPrompt: (state, action: PayloadAction<Prompt>) => {
      state.prompt = action.payload
    },
  }
})

export const { setFile, setPrompt } = RemaykGenSlice.actions


export const selectFile = (state: RootState) => state.remaykGen.file
export const selectPrompt = (state: RootState) => state.remaykGen.prompt
export const selectPrompts = (state: RootState) => state.remaykGen.prompts

export default RemaykGenSlice.reducer