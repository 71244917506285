import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import './MaykButton.css'

export interface MaykButtonProps {
    className?: string;
    title: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

export const MaykButton = (props: MaykButtonProps) => {
    return (
        <LoadingButton
        className={`${props.className} MaykButton`}
        loading={props.loading}
        loadingIndicator={
            <CircularProgress
            sx={{
                color: "#000000",
            }}
            size={'5vh'}
            thickness={5} />
        }
        disabled={props.disabled}
        onClick={() => props.onClick()}
        sx={{
            ':hover': {
                backgroundColor: "#DBFF00",
                opacity: 0.8,
            },
            ':disabled': {
                color: '#333',
                backgroundColor: "#555"
            }
        }}
        >
            {props.title}
        </LoadingButton>
    )    
}

export default MaykButton
