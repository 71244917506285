import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDSfCqphnbnLnflGghUrROgER7npZySAkY",
  authDomain: "remayk-it.firebaseapp.com",
  projectId: "remayk-it",
  storageBucket: "remayk-it.appspot.com",
  messagingSenderId: "648520841666",
  appId: "1:648520841666:web:1e09c4b427d1e0fc1a7e5b",
  measurementId: "G-N1CW7615S3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default {app, gaLog: (event: string) => logEvent(getAnalytics(app), event)}
