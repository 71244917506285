import {useEffect, useState} from 'react'
import MaykButton from '../shared/MaykButton/MaykButton'
import PromptSelector from './PromptSelector/PromptSelector'
import './RemaykGenPage.css'
import SongSelector from './SongSelector/SongSelector'
import { useAppSelector } from '../../model/Hooks'
import { selectFile, selectPrompt } from '../../model/reducers/RemaykGenReducer'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import firebase from '../../helpers/firebase'

const RemaykGenPage = () => {
    const [loading, setLoading] = useState(false)
    const [audio] = useState<string>()
    const navigate = useNavigate();

    const prompt = useAppSelector(selectPrompt)
    const fileUpload = useAppSelector(selectFile)

    useEffect(() => {
        firebase.gaLog('main_page_opened')
    }, []);

    const remixSong = async () => {
        setLoading(true)
        firebase.gaLog('remix_song_button_clicked')

        if (!prompt || !fileUpload) {
            setLoading(false)
            return
        }

        var file;
        var url;

        if (fileUpload.url.toLowerCase().includes("youtube.com")) {
            console.log('Using remote ', fileUpload.url)
            url = fileUpload.url
        } else {
            console.log('Downloading ', fileUpload.url)
            const inputFileResponse = await axios.request({
                url: fileUpload.url,
                method: 'GET',
                responseType: 'blob'
            });
            if (inputFileResponse.data) {
                file = new File([inputFileResponse.data], 'input.mp3')
            }
        }

        if (!file && !url) {
            console.error('No file or url found! ', fileUpload.url)
            setLoading(false)
            return
        }

        try {
            console.log('Remixing')
            var formData = new FormData()
            if (file) {
                formData.append("file", file, 'input');
            }
            if (url) {
                formData.append("url", url);
            }
            formData.append("prompt", prompt.prompt)
            const response = await axios.request({
                url: 'https://remayk-server-i6uquyesjq-uc.a.run.app/remix',
                method: 'POST',
                data: formData,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                }
            })
            console.log('Wow remixed')
            if (response?.data?.id) {
                firebase.gaLog('successful_remix_request')
                navigate('/remix/' + response.data.id)
            }
        } catch (error: any) {
            firebase.gaLog('unsuccessful_remix_request')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='remayk-gen-page-container'>
            <div className='remayk-gen-page-content'>
                <div className='remayk-gen-page-title'>
                    Create your AI remix
                </div>
                <SongSelector />
                <PromptSelector />
                <MaykButton
                className='remayk-gen-page-create-button'
                title={audio ? 'Create a new one' : 'Create'}
                loading={loading}
                onClick={() => remixSong()} />
                {audio &&
                    <audio className='remayk-gen-page-audio' controls src={audio} />
                }
            </div>
        </div>
    )
}

export default RemaykGenPage
