import {useEffect, useState, useCallback} from 'react'
import {useParams} from "react-router-dom"
import axios from 'axios'
import {CircularProgress} from "@mui/material";
import firebase from "../../helpers/firebase";

export default function Remix() {
  const {id} = useParams();
  const [remix, setRemix] = useState<{ status: string, remix: string }>()
  const getRemix = useCallback((interval?: NodeJS.Timer) => {
    axios.get(`https://remayk-server-i6uquyesjq-uc.a.run.app/remix/${id}`).then(({data}) => {
      setRemix(data)
      if (interval && data.remix) clearInterval(interval)
    })
  }, [id])

  useEffect(() => {
    getRemix()
    let interval = setInterval(() => {
      getRemix(interval)
    }, 5000)

    firebase.gaLog('remix_page_opened')

    return () => {
      clearInterval(interval)
    }
  }, [getRemix])

  return (
    <div className='remayk-gen-page-container'>
      <div className='remayk-gen-page-content'>
        <div className='remayk-gen-page-title'>
          Your AI remix
        </div>
        {remix ? <>
          {!remix.remix && <CircularProgress
            sx={{
              color: "white",
            }}
            size={'5vh'}
            thickness={5} />}
          <div className='remayk-gen-page-title'>
            STATUS: {remix.status}
          </div>
          <div className='remayk-gen-page-description'>
            {remix.remix ? 'Your remix is ready!' : 'Your remix is being created, it may take up to 15 minutes to generate'}
          </div>
          {remix.remix &&
            <audio className='remayk-gen-page-audio' controls src={remix.remix}/>
          }
        </> : <div className='remayk-gen-page-description'>
          Loading...
        </div>
        }
      </div>
    </div>
  )
}
