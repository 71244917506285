
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import './AppHeader.css';

interface Prop {
    onMenuTap: () => void
}

export const AppHeader = (props: PropsWithChildren<Prop>) => {
    const navigate = useNavigate()
    return (
        <div className="header-container">
            <button
                className="header-home-text"
                onClick={() => navigate("/")}>
                myremix.ai
            </button>
            {/* <div className="Header-menu"  onClick={props.onMenuTap}>
                <img
                src="/menu.png"
                alt="menu"
                className="Header-home-icon" />
            </div> */}
        </div>
    )
};

export default AppHeader;