import './App.css';
import { Route, Routes } from 'react-router-dom';
import RemaykGenPage from '../RemaykGenPage/RemaykGenPage';
import AppHeader from '../AppHeader/AppHeader';
import Remix from "../RemixPage/Remix";

function App() {
  return (
    <div className='app-container'>
      <video className="app-background" autoPlay loop muted playsInline>
        <source src="/starfield.mp4" type='video/mp4' />
      </video>
      <AppHeader onMenuTap={() => {}} />
      <div className='app-content'>
        <Routes>
          <Route path='/' element={<RemaykGenPage />} />
          <Route path='/remix/:id' element={<Remix />} />
        </Routes>
      </div>
    </div>
  )
}

export default App;
